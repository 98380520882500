import { useMutation } from '@tanstack/react-query';
import { ocrEmployeeService } from '../../../Domain/Connections/Services/Connection/OCR';
import type { UpdateOCREmployeeParams } from '../../../Domain/Connections/Services/Connection/OCR';

type UpdateOCREmployeeStatus = UpdateOCREmployeeParams;

const mutationFn = (data: UpdateOCREmployeeStatus) => {
  return ocrEmployeeService.updateOCREmployee(data);
};

export const useConnectionUpdateOCREmployeeMutation = () => {
  return useMutation<unknown, unknown, UpdateOCREmployeeStatus>(mutationFn);
};
