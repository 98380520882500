import React from 'react';

import type { Theme } from '@mui/material/styles';
import type { GridProps, SxProps, TypographyProps } from '@mui/material';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import DetailsPanelRow from '../DetailsPanelRow';
import { IntlMessage } from '../Intl';

import type { DetailsPanelColumn } from './DetailsPanel.types';

import {
  DETAILS_PANEL_BODY_ID,
  DivHeaderContentStyled,
  GridActionsStyled,
  GridBodyStyled,
  GridColumnStyled,
  GridHeaderStyled,
  PaperBackgroundStyled,
  TypographyColumnSubTitleStyled,
  TypographyColumnTitleStyled,
} from './DetailsPanel.styles';

type DetailsPanelProps = {
  header: string | React.ReactNode;
  headerButton?: React.ReactNode;
  body?: React.ReactNode;
  columns?: Array<DetailsPanelColumn>;
  columnsContainerSx?: SxProps<Theme>;
  actions?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  titleVariant?: TypographyProps['variant'];
  subtitleVariant?: TypographyProps['variant'];
  divider?: boolean;
  scrollBody?: boolean;
  removeJustifyContent?: boolean;
};

const DetailsPanel = ({
  header,
  headerButton,
  body,
  columns,
  columnsContainerSx,
  actions,
  loading = false,
  disabled = false,
  titleVariant = 'subtitle2',
  subtitleVariant = 'body1',
  divider = false,
  scrollBody = false,
  removeJustifyContent = false,
}: DetailsPanelProps) => {
  const { breakpoints } = useTheme<Theme>();
  const smallScreen = !useMediaQuery(breakpoints.up('md'));

  const renderColumn = React.useCallback(
    (column, index) => {
      if (!columns) {
        return null;
      }
      const columnsLength = columns && columns.length >= 2 ? 2 : columns.length;
      const columnWidth = Math.floor(12 / columnsLength);
      const columnsPerRow = Math.floor(12 / columnWidth);
      const hasDividerLeft = divider && !smallScreen && index % columnsPerRow !== 0;
      const hasDividerRight = divider && !smallScreen && index % columnsPerRow === 0;
      const hasDividerTop = divider && smallScreen && index !== 0 && columns.length > 1;
      const hasDividerBottom = divider && smallScreen && index === 0 && columns.length > 1;

      return (
        <GridColumnStyled
          key={column.id ?? index}
          item
          md={columnWidth as GridProps['md']}
          xs={12}
          hasDividerLeft={hasDividerLeft}
          hasDividerRight={hasDividerRight}
          hasDividerTop={hasDividerTop}
          hasDividerBottom={hasDividerBottom}
        >
          {column.title && (
            <TypographyColumnTitleStyled
              variant={titleVariant}
              withMarginTop={!!header}
              withMarginBottom={!column.subtitle}
            >
              <IntlMessage value={column.title} />
            </TypographyColumnTitleStyled>
          )}
          {column.subtitle && (
            <TypographyColumnSubTitleStyled variant={subtitleVariant} withMarginTop={!column.title}>
              <IntlMessage value={column.subtitle} />
            </TypographyColumnSubTitleStyled>
          )}
          <DetailsPanelRow loading={loading} rows={column.rows} disabled={disabled} />
        </GridColumnStyled>
      );
    },
    [columns, disabled, divider, header, loading, smallScreen, subtitleVariant, titleVariant],
  );

  return (
    <PaperBackgroundStyled withScrollBody={scrollBody}>
      <Grid
        container
        spacing={2}
        direction={'column'}
        justifyContent={removeJustifyContent ? undefined : 'space-between'}
        alignItems={'stretch'}
      >
        {header && (
          <GridHeaderStyled item container xs={'auto'}>
            <Grid item xs={true}>
              <DivHeaderContentStyled withIcon={!!headerButton}>
                <Typography variant={'overline'}>{header}</Typography>
                {!!headerButton && headerButton}
              </DivHeaderContentStyled>
            </Grid>
          </GridHeaderStyled>
        )}
        {body && (
          <GridBodyStyled id={DETAILS_PANEL_BODY_ID} item container xs={'auto'} withScrollBody={scrollBody}>
            {body}
          </GridBodyStyled>
        )}
        {columns && (
          <Grid item container spacing={2} sx={columnsContainerSx}>
            {columns.map(renderColumn)}
          </Grid>
        )}
        {actions && (
          <GridActionsStyled item xs={'auto'}>
            <Box sx={{ padding: '2px' }}>{actions}</Box>
          </GridActionsStyled>
        )}
      </Grid>
    </PaperBackgroundStyled>
  );
};

export default React.memo(DetailsPanel);
