import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { Connection, UseQueryWrapper } from 'app/types';
import { useSnakeBar } from '../../useSnakeBar';
import type { OCRFile } from '../../../types/Connection/ocr.types';
import { ocrFileService } from '../../../Domain/Connections/Services/Connection/OCR';

type GetTaskQueryVariables = {
  connectionId: Connection['connectionId'] | undefined;
  page?: number;
  perPage?: number;
};

type GetTaskQueryResultData = {
  data: OCRFile[];
  totalCount: number;
};

const queryFn = (variables?: GetTaskQueryVariables) => async () => {
  const response = await ocrFileService.getOCRFiles(variables);
  return response.data;
};

const QUERY_KEY = 'ocrFiles';

export const useConnectionGetOCRFilesDataQuery: UseQueryWrapper<GetTaskQueryResultData, GetTaskQueryVariables> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetTaskQueryResultData>([QUERY_KEY, ...Object.values(variables)], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
