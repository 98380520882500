import type { Insurer } from './insurer.types';

export enum ProductPensionSchemeEnum {
  Regular = 'regular',
  Adequate = 'adequate',
}

export enum ProductTypeEnum {
  Pension = 'pension',
  Income = 'income',
}

export enum ProductIncomeTypeEnum {
  SickLeave = 'sickLeave',
  WgaERD = 'WGA_ERD',
  WIAExcedent = 'WIA_Excendent',
  IVAExcedent = 'IVA_Excedent',
  WGAGat = 'WGA_GAT',
  WIABodem = 'WIA_Bodem',
  Pension = 'pension',
  PolicyPackage = 'policy-package',
}

export type ProductCredential = {
  '@id': string;
  '@type': string;
  credentialId: string;
  packageType: string;
};

export type ProductInsurer = Pick<Insurer, '@id' | '@type' | 'insurerId' | 'insurerName'>;

export type Product = {
  '@id': string;
  '@type': string;
  credentialId: string | null;
  description: string | null;
  insurerId: string;
  pensionScheme: ProductPensionSchemeEnum | null;
  productId: string;
  productName: string;
  status: boolean;
  type: ProductTypeEnum;
  credential: ProductCredential | null;
  insurer: ProductInsurer;
};
