import axios from 'axios';
import type { Connection } from '../../../../../types';

type GetOCREmployeesParams = {
  connectionId: Connection['connectionId'] | undefined;
  page?: number;
  perPage?: number;
  fileId: string;
};

export type UpdateOCREmployeeParams = {
  connectionId: Connection['connectionId'] | undefined;
  employeeId: string;
  fieldName: string;
  fieldValue: string | number | Date;
};

const getOCREmployees = (params?: GetOCREmployeesParams) => {
  return axios.get(`connections/${params?.connectionId}/ocr-employees`, {
    params,
  });
};

const updateOCREmployee = (params: UpdateOCREmployeeParams) => {
  return axios.patch(
    `connections/${params?.connectionId}/ocr-employees/${params.employeeId}`,
    {
      fieldName: params.fieldName,
      fieldValue: params.fieldValue,
    },
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    },
  );
};

export const ocrEmployeeService = {
  getOCREmployees,
  updateOCREmployee,
};
