import React from 'react';
import { Alert, AlertTitle, Box, Card, Checkbox, FormControlLabel, FormGroup, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';
import {
  APPROVAL_NEEDED_PROCESS_RESULTS,
  CAN_COMPARE,
  CAN_COMPARE_COMPLETED,
  CAN_DOWNLOAD,
  COMPLETE_PROCESS_RESULTS,
  FAILED_PROCESS_RESULTS,
  PENDING_RESULTS,
} from '../../../../Pages/ConnectionDetailsPage/BatchesTab/constants';
import type {
  FilterableTableColumnType,
  FilterableTableRowMapperFunc,
  FilterTableOnPaginationChangeFunc,
  KebabMenuItem,
} from '../../../../../../components';
import { ConfirmDialog, FilterableTable, KebabMenu } from '../../../../../../components';
import { formatDateTime } from '../../../../../../utils/formatter/dateTimeFormatter';
import { HasAccessTo } from 'app/components/Authorization';
import { BATCH, CONNECTION } from '../../../../../../common/Authorization/entities';
import { DOWNLOAD, EDIT } from '../../../../../../common/Authorization/permissions';
import { TranslationKeys } from '../../../../../../translations';
import ActionButton from '../../../../../../components/ActionButton';

const TableColumns = {
  TriggeredAt: 'triggeredAt',
  FinishedAt: 'finishedAt',
  TriggeredBy: 'triggeredBy',
  EventsNumber: 'eventsNumber',
  MutationsNumber: 'mutationsNumber',
  ErrorMessagesNumber: 'errorMessagesNumber',
  BatchStatus: 'batchStatus',
  MutationsStatus: 'mutationsStatus',
  Actions: 'col-actions',
};

type ProcessesTableProps = {
  processes: Record<string, any>[];
  employerId: string;
  connectionId: string;
  page: number;
  rowsPerPage: number;
  totalCount: number;
  loading: boolean;
  onPaginationChange: FilterTableOnPaginationChangeFunc;
  mutationsStatus: Record<string, any>;
  handleClickRetry: (data: Record<string, any>) => void;
  handleClickApprove: (data: Record<string, any>) => void;
  handleClickDecline: (data: Record<string, any>) => void;
  handleClickDownload: (data: Record<string, any>) => void;
  handleClickDownloadLog: (data: Record<string, any>) => void;
  handleErrorMessagesClick: (data: Record<string, any>) => void;
  handleEventsClick: (batchId: string, mongoId: string) => void;
  handleMutationsClick: (batchId: string, mongoId: string) => void;
  handleClickForceSend: (data: Record<string, any>, withFailed: boolean) => void;
  handleClickForceDecline: (data: Record<string, any>) => void;
  handleMutationStatusClick: (data: Record<string, any>) => void;
  handleClearAlertMutationsStatus: (data: Record<string, any>) => void;
};

export const ProcessesTable = ({
  employerId,
  connectionId,
  processes,
  mutationsStatus,
  onPaginationChange,
  page,
  rowsPerPage,
  totalCount,
  loading,
  handleClickRetry,
  handleClickApprove,
  handleClickDecline,
  handleClickDownload,
  handleClickDownloadLog,
  handleErrorMessagesClick,
  handleEventsClick,
  handleMutationsClick,
  handleClickForceSend,
  handleClickForceDecline,
  handleMutationStatusClick,
  handleClearAlertMutationsStatus,
}: ProcessesTableProps) => {
  const [confirmApproveOpen, setConfirmApproveOpen] = React.useState(false);
  const [selectedApproveProcess, setSelectedApproveProcess] = React.useState<Record<string, any> | null>(null);
  const [confirmDeclineOpen, setConfirmDeclineOpen] = React.useState(false);
  const [selectedDeclineProcess, setSelectedDeclineProcess] = React.useState<Record<string, any> | null>(null);
  const [confirmForceSend, setConfirmForceSend] = React.useState(false);
  const [selectedForceSend, setSelectedForceSend] = React.useState<Record<string, any> | null>(null);
  const [confirmForceDecline, setConfirmForceDecline] = React.useState(false);
  const [selectedForceDecline, setSelectedForceDecline] = React.useState(null);
  const [withFailed, setTryFailed] = React.useState(false);

  const disabledIfNoAccessToBatchEdit = !HasAccessTo(BATCH, EDIT);
  const disabledIfNoAccessToConnectionDownload = !HasAccessTo(CONNECTION, DOWNLOAD);

  const getTranslatedResultId = React.useCallback((result: string) => {
    return `connection.processes.result.${result}`;
  }, []);

  const handleApprove = React.useCallback(process => {
    setConfirmApproveOpen(true);
    setSelectedApproveProcess(process);
  }, []);

  const approveProcess = React.useCallback(() => {
    if (selectedApproveProcess) {
      handleClickApprove(selectedApproveProcess);
    }
  }, [handleClickApprove, selectedApproveProcess]);

  const handleForceSend = React.useCallback(process => {
    setConfirmForceSend(true);
    setSelectedForceSend(process);
  }, []);

  const forceSendProcess = React.useCallback(() => {
    if (selectedForceSend) {
      handleClickForceSend(selectedForceSend, withFailed);
    }
  }, [handleClickForceSend, selectedForceSend, withFailed]);

  const handleForceDecline = React.useCallback(
    process => {
      setConfirmForceDecline(true);
      setSelectedForceDecline(process);
    },
    [setSelectedForceDecline, setConfirmForceDecline],
  );

  const forceDeclineProcess = React.useCallback(() => {
    if (selectedForceDecline) {
      handleClickForceDecline(selectedForceDecline);
    }
  }, [handleClickForceDecline, selectedForceDecline]);

  const handleDecline = React.useCallback(process => {
    setConfirmDeclineOpen(true);
    setSelectedDeclineProcess(process);
  }, []);

  const declineProcess = React.useCallback(() => {
    if (selectedDeclineProcess) {
      handleClickDecline(selectedDeclineProcess);
    }
  }, [handleClickDecline, selectedDeclineProcess]);

  const handleCheckFailed = React.useCallback(event => {
    setTryFailed(event.target.checked);
  }, []);

  const canDeclineProcess = React.useCallback(({ batchStatus, triggeredAt }: Record<string, any>) => {
    if (!PENDING_RESULTS.includes(batchStatus)) return true;

    const timeDifference = Date.now() - new Date(triggeredAt).getTime();
    return timeDifference > 24 * 60 * 60 * 1000;
  }, []);

  const tableHeaders = React.useMemo<Array<FilterableTableColumnType>>(() => {
    return [
      {
        name: TableColumns.TriggeredAt,
        title: <FormattedMessage id={TranslationKeys.connection_processes_triggeredAt} />,
      },
      {
        name: TableColumns.FinishedAt,
        title: <FormattedMessage id={TranslationKeys.connection_processes_finishedAt} />,
      },
      {
        name: TableColumns.TriggeredBy,
        title: <FormattedMessage id={TranslationKeys.connection_processes_triggeredBy} />,
      },
      {
        name: TableColumns.EventsNumber,
        title: <FormattedMessage id={TranslationKeys.connection_processes_eventsNumber} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.MutationsNumber,
        title: <FormattedMessage id={TranslationKeys.connection_processes_mutationsNumber} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.ErrorMessagesNumber,
        title: <FormattedMessage id={TranslationKeys.connection_processes_errorMessagesNumber} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.BatchStatus,
        title: <FormattedMessage id={TranslationKeys.connection_processes_batch_status} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.MutationsStatus,
        title: <FormattedMessage id={TranslationKeys.connection_processes_mutations_status} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.Actions,
        isActionsColumn: true,
      },
    ];
  }, []);

  const getEventsNumberRowContent = React.useCallback(
    data => {
      if (data.eventsCount) {
        return (
          <ActionButton
            messageId={data.eventsCount}
            onClick={() => handleEventsClick(data.batchId, data.mongoId)}
            variant={'text'}
          />
        );
      }
      return data.eventsCount;
    },
    [handleEventsClick],
  );

  const getMutationsNumberRowContent = React.useCallback(
    data => {
      if (data.mutationsCount) {
        return (
          <ActionButton
            messageId={data.mutationsCount}
            onClick={() => handleMutationsClick(data.batchId, data.mongoId)}
            variant={'text'}
          />
        );
      }
      return data.mutationsCount;
    },
    [handleMutationsClick],
  );

  const getErrorMessagesNumberRowContent = React.useCallback(
    data => {
      if (data.messages.length > 0) {
        return (
          <ActionButton
            messageId={data.messages.length}
            onClick={() => handleErrorMessagesClick(data['@id'])}
            variant={'text'}
          />
        );
      }
      return data.messages.length;
    },
    [handleErrorMessagesClick],
  );

  const getMutationsStatusRowContent = React.useCallback(
    data => {
      return (
        <>
          <Box>
            <ActionButton
              messageId={TranslationKeys.connection_processes_mutations_status_check}
              onClick={() => handleMutationStatusClick(data)}
              variant={'text'}
            />
          </Box>
          <Box>
            {mutationsStatus && mutationsStatus.mongoId === data.mongoId && (
              <Alert
                sx={{ textTransform: 'capitalize' }}
                severity="info"
                action={
                  <IconButton aria-label="close" color="inherit" size="small" onClick={handleClearAlertMutationsStatus}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <AlertTitle>
                  {Object.entries(mutationsStatus)
                    .filter(([key]) => key !== 'mongoId')
                    .map(item => `${item[0]}: ${item[1]}`)
                    .join(', ')}
                </AlertTitle>
              </Alert>
            )}
          </Box>
        </>
      );
    },
    [handleClearAlertMutationsStatus, handleMutationStatusClick, mutationsStatus],
  );

  const buildRowActions = React.useCallback(
    data => {
      const actions: Array<KebabMenuItem> = [];

      if (CAN_COMPARE.includes(data.batchStatus) || CAN_COMPARE_COMPLETED.includes(data.batchStatus)) {
        actions.push({
          element: <FormattedMessage id={TranslationKeys.connection_processes_button_compare} />,
          to: `/employers/${employerId}/connections/${connectionId}/batches/${data.batchId}/compare`,
          disabled: disabledIfNoAccessToBatchEdit,
        });
      }

      if (APPROVAL_NEEDED_PROCESS_RESULTS.includes(data.batchStatus)) {
        actions.push({
          element: <FormattedMessage id={TranslationKeys.connection_processes_button_approve} />,
          onClick: () => handleApprove(data),
          disabled: disabledIfNoAccessToBatchEdit,
        });
      }
      if (
        PENDING_RESULTS.includes(data.batchStatus) ||
        APPROVAL_NEEDED_PROCESS_RESULTS.includes(data.batchStatus) ||
        CAN_COMPARE.includes(data.batchStatus)
      ) {
        const disableDecileOnPending = !canDeclineProcess(data);
        actions.push({
          element: <FormattedMessage id={TranslationKeys.connection_processes_button_decline} />,
          onClick: () => handleDecline(data),
          disabled: disabledIfNoAccessToBatchEdit || disableDecileOnPending,
        });
      }
      if (FAILED_PROCESS_RESULTS.includes(data.batchStatus)) {
        actions.push(
          {
            element: <FormattedMessage id={TranslationKeys.connection_processes_button_retry} />,
            onClick: () => handleClickRetry(data),
            disabled: disabledIfNoAccessToBatchEdit,
          },
          {
            element: <FormattedMessage id={TranslationKeys.connection_processes_button_cancel} />,
            onClick: () => handleDecline(data),
            disabled: disabledIfNoAccessToBatchEdit,
          },
        );
      }
      if (COMPLETE_PROCESS_RESULTS.includes(data.batchStatus) || CAN_COMPARE_COMPLETED.includes(data.batchStatus)) {
        actions.push(
          {
            element: <FormattedMessage id={TranslationKeys.connection_processes_button_forceSend} />,
            onClick: () => handleForceSend(data),
            disabled: disabledIfNoAccessToBatchEdit,
          },
          {
            element: <FormattedMessage id={TranslationKeys.connection_processes_button_forceDecline} />,
            onClick: () => handleForceDecline(data),
            disabled: disabledIfNoAccessToBatchEdit,
          },
        );
      }
      if (CAN_DOWNLOAD.includes(data.batchStatus)) {
        actions.push(
          {
            element: <FormattedMessage id={TranslationKeys.connection_processes_button_download} />,
            onClick: () => handleClickDownload(data),
            disabled: disabledIfNoAccessToConnectionDownload,
          },
          {
            element: <FormattedMessage id={'connection.processes.button.download-log'} />,
            onClick: () => handleClickDownloadLog(data),
            disabled: disabledIfNoAccessToConnectionDownload,
          },
        );
      }

      if (actions.length === 0) {
        return null;
      }

      return <KebabMenu items={actions} />;
    },
    [
      connectionId,
      disabledIfNoAccessToBatchEdit,
      disabledIfNoAccessToConnectionDownload,
      employerId,
      canDeclineProcess,
      handleApprove,
      handleClickDownload,
      handleClickDownloadLog,
      handleClickRetry,
      handleDecline,
      handleForceDecline,
      handleForceSend,
    ],
  );

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<any>>(
    data => {
      return {
        data: {
          [TableColumns.TriggeredAt]: formatDateTime(data.triggeredAt),
          [TableColumns.FinishedAt]: formatDateTime(data.finishedAt),
          [TableColumns.TriggeredBy]: data.triggeredBy,
          [TableColumns.EventsNumber]: getEventsNumberRowContent(data),
          [TableColumns.MutationsNumber]: getMutationsNumberRowContent(data),
          [TableColumns.ErrorMessagesNumber]: getErrorMessagesNumberRowContent(data),
          [TableColumns.BatchStatus]: <FormattedMessage id={getTranslatedResultId(data.batchStatus)} />,
          [TableColumns.MutationsStatus]: getMutationsStatusRowContent(data),
          [TableColumns.Actions]: buildRowActions(data),
        },
      };
    },
    [
      buildRowActions,
      getErrorMessagesNumberRowContent,
      getEventsNumberRowContent,
      getMutationsNumberRowContent,
      getMutationsStatusRowContent,
      getTranslatedResultId,
    ],
  );

  const pagination = React.useMemo(() => {
    return {
      pageSize: rowsPerPage,
      totalCount,
      page,
    };
  }, [rowsPerPage, totalCount, page]);

  return (
    <Card>
      <FilterableTable
        loading={loading}
        rows={processes}
        columns={tableHeaders}
        rowMapper={tableRowMapper}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
      />

      <ConfirmDialog
        title={<FormattedMessage id={TranslationKeys.connection_processes_approveConfirmationTitle} />}
        open={confirmApproveOpen}
        setOpen={setConfirmApproveOpen}
        onConfirm={approveProcess}
      >
        <FormattedMessage id={TranslationKeys.connection_processes_approveConfirmationMessage} />
      </ConfirmDialog>

      <ConfirmDialog
        title={<FormattedMessage id={TranslationKeys.connection_processes_declineConfirmationTitle} />}
        open={confirmDeclineOpen}
        setOpen={setConfirmDeclineOpen}
        onConfirm={declineProcess}
      >
        <FormattedMessage id={TranslationKeys.connection_processes_declineConfirmationMessage} />
      </ConfirmDialog>

      <ConfirmDialog
        title={<FormattedMessage id={TranslationKeys.connection_processes_forceSendConfirmationTitle} />}
        open={confirmForceSend}
        setOpen={setConfirmForceSend}
        onConfirm={forceSendProcess}
      >
        <>
          <FormattedMessage id={TranslationKeys.connection_processes_forceSendConfirmationMessage} />
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox color={'secondary'} checked={withFailed} onChange={handleCheckFailed} name="withFailed" />
              }
              label={<FormattedMessage id={TranslationKeys.connection_processes_forceSendConfirmationWithFailed} />}
            />
          </FormGroup>
        </>
      </ConfirmDialog>

      <ConfirmDialog
        title={<FormattedMessage id={TranslationKeys.connection_processes_forceDeclineConfirmationTitle} />}
        open={confirmForceDecline}
        setOpen={setConfirmForceDecline}
        onConfirm={forceDeclineProcess}
      >
        <FormattedMessage id={TranslationKeys.connection_processes_forceDeclineConfirmationMessage} />
      </ConfirmDialog>
    </Card>
  );
};
