export type OCRFile = {
  id: string | null;
  averageAccuracy: number | null;
  fileId: string;
  fileName: string;
  rowCount: number | null;
  status: OCRStatus;
  uploadDate: string;
  year: number | null;
};

export type AccuracyField = {
  value: string | number | Date;
  accuracy: number | null;
};

export type ExistingEmployee = {
  grossWage: number;
  aofWkoPremium: number;
  startDate: Date;
  endDate: Date;
};

export type OCREmployee = {
  employeeId: string;
  fields: Record<string, AccuracyField>;
  existingEmployee: ExistingEmployee | null;
};

export enum OCRStatus {
  SENT = 'sent',
  FAILED = 'failed',
  PENDING = 'pending',
  WAITING_ON_APPROVAL = 'waiting_on_approval',
  APPROVED = 'approved',
  COMPLETED = 'completed',
}
