import axios from 'axios';
import type { Connection } from '../../../../../types';

type GetOCRFIlesParams = {
  connectionId: Connection['connectionId'] | undefined;
  page?: number;
  perPage?: number;
};

export type UpdateOCRFIleStatusParams = {
  connectionId: Connection['connectionId'] | undefined;
  id: string | null;
  status: string;
  ids?: string[];
};

type DownloadOCRFileParams = {
  connectionId: Connection['connectionId'] | undefined;
  id: string;
};

const getOCRFiles = (params?: GetOCRFIlesParams) => {
  return axios.get(`connections/${params?.connectionId}/ocr-files`, {
    params,
  });
};

const patchOCRFileStatus = (params?: UpdateOCRFIleStatusParams) => {
  return axios.patch(
    `connections/${params?.connectionId}/ocr-files/${params?.id}`,
    { status: params?.status, ids: params?.ids },
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    },
  );
};

const downloadOCRfile = ({ connectionId, id }: DownloadOCRFileParams) => {
  return axios.get(`/connections/${connectionId}/ocr-files/${id}/download`);
};

export const ocrFileService = {
  downloadOCRfile,
  getOCRFiles,
  patchOCRFileStatus,
};
