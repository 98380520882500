import React from 'react';
import type { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useConnectionGetOCREmployeesDataQuery } from '../../../../../../hooks/api/connection/useConnectionGetOCREmployeesDataQuery';
import { useParams } from 'react-router-dom';
import { EditableFieldFactory } from '../../../../../../components/FormikField/Factory/EditableFieldFactory';
import { IntlMessage } from '../../../../../../components/Intl';
import { debounce } from '../../../../../../utils';
import { useConnectionUpdateOCREmployeeMutation } from '../../../../../../hooks/api/connection/useConnectionUpdateOCREmployeeMutation';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { TooltipStyled } from '../../../../../../components/FormikField/Select/Select.styles';
import { OCRStatus, type AccuracyField, type OCREmployee } from '../../../../../../types/Connection/ocr.types';
import type { UseMutateFunction } from '@tanstack/react-query';
import type { UpdateOCRFIleStatusParams } from '../../../../Services/Connection/OCR';

const ACCURACY_MIN_THRESHOLD = 100;
const EXISTING_EMPLOYEE_FIELD_TO_CHECK = ['grossWage', 'aofWkoPremium', 'startDate', 'endDate'];

type EmployeeWizardProps = {
  onClose: () => void;
  show: boolean;
  onApprove: UseMutateFunction<unknown, unknown, UpdateOCRFIleStatusParams, unknown>;
  fileId: string;
  fileUuid: string;
};
export const EditRowsPopup = ({ onClose, show, onApprove, fileId, fileUuid }: EmployeeWizardProps) => {
  const { connectionId } = useParams();
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [selectedOCREmployees, setSelectedOCREmployees] = React.useState<string[]>([]);
  const {
    data: ocrEmployees,
    isLoading: loadingOcrEmployees,
    refetch: refetchOCREmployees,
    isRefetching,
  } = useConnectionGetOCREmployeesDataQuery({
    variables: {
      connectionId: connectionId,
      perPage,
      page: page,
      fileId: fileUuid,
    },
  });
  const handleToggleOcrEmployee = React.useCallback(
    employeeId => {
      const alreadyExists = selectedOCREmployees.find(r => r === employeeId);

      setSelectedOCREmployees(
        alreadyExists
          ? selectedOCREmployees.filter(item => item !== employeeId)
          : [...selectedOCREmployees, employeeId],
      );
    },
    [selectedOCREmployees, setSelectedOCREmployees],
  );

  const headers = new Set<string>();
  (ocrEmployees?.data ?? []).forEach(employee => Object.keys(employee.fields).forEach(key => headers.add(key)));

  const EditableFieldFactoryLowAccuracy = styled(EditableFieldFactory)({
    border: '2px solid rgba(255, 0, 0, 0.5)',
    borderRadius: '7px',
  });

  const updateEmployeeField = useConnectionUpdateOCREmployeeMutation();

  const getTooltip = (employee: OCREmployee, field: AccuracyField, fieldName: string) => {
    return (
      <>
        <p>Accuracy: {field?.accuracy ?? 0}%</p>
        {employee.existingEmployee && EXISTING_EMPLOYEE_FIELD_TO_CHECK.includes(fieldName) && (
          <p>Existing Value: {(employee.existingEmployee as Record<string, any>)[fieldName]}</p>
        )}
      </>
    );
  };

  const onEditField = React.useCallback(
    value => {
      updateEmployeeField.mutate(
        {
          connectionId: connectionId,
          employeeId: value.name.split('-')[0],
          fieldName: value.name.split('-')[1],
          fieldValue: value.value,
        },
        {
          onSuccess: () => {
            refetchOCREmployees();
          },
        },
      );
    },
    [connectionId, updateEmployeeField, refetchOCREmployees],
  );

  return (
    <>
      <Dialog open={show} onClose={onClose} fullWidth maxWidth="md" aria-labelledby="process-events-dialog">
        <DialogTitle id="process-events-dialog">
          <FormattedMessage id="ocr.row.count" />
        </DialogTitle>
        <DialogContent>
          {loadingOcrEmployees && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {Array.from(headers).map(header => (
                  <TableCell key={header}>
                    <FormattedMessage id={`ocr.employee.field.${header}`} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(ocrEmployees?.data ?? []).map(employee => (
                <TableRow
                  hover
                  key={employee.employeeId}
                  style={employee.existingEmployee ? { backgroundColor: 'yellow' } : {}}
                >
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={!!selectedOCREmployees.find(r => r === employee.employeeId)}
                      onChange={() => handleToggleOcrEmployee(employee.employeeId)}
                    />
                  </TableCell>
                  {Array.from(headers).map(header => (
                    <TableCell key={`${employee.employeeId}-${header}`}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {(employee.fields[header]?.accuracy ?? 0) <= ACCURACY_MIN_THRESHOLD && (
                          <EditableFieldFactoryLowAccuracy
                            editing={true}
                            value={employee.fields[header]?.value}
                            onChange={debounce(onEditField, 3000)}
                            field={{
                              variant: 'slim',
                              disabled: isRefetching,
                              name: `${employee.employeeId}-${header}`,
                            }}
                          />
                        )}
                        {(employee.fields[header]?.accuracy ?? 0) > ACCURACY_MIN_THRESHOLD && (
                          <EditableFieldFactory
                            editing={false}
                            value={employee.fields[header]?.value}
                            onChange={debounce(onEditField, 3000)}
                            field={{
                              variant: 'slim',
                              name: `${employee.employeeId}-${header}`,
                            }}
                          />
                        )}
                        <TooltipStyled title={getTooltip(employee, employee.fields[header], header)} placement={'top'}>
                          <FontAwesomeIcon icon={faQuestionCircle} />
                        </TooltipStyled>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <TablePagination
            component="div"
            count={ocrEmployees?.totalCount ?? 0}
            onPageChange={(event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
              setPage(newPage + 1);
            }}
            onRowsPerPageChange={(rowSize: React.ChangeEvent<HTMLInputElement>) => {
              setPerPage(rowSize.target.value as unknown as React.SetStateAction<number>);
            }}
            page={ocrEmployees?.totalCount === 0 ? 0 : Math.max(0, page - 1)}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
          <Button
            onClick={() =>
              onApprove({
                connectionId,
                id: fileId,
                status: OCRStatus.APPROVED,
                ids: selectedOCREmployees,
              })
            }
            disabled={selectedOCREmployees.length === 0}
          >
            <FormattedMessage id="connection.processes.button.approve" />
          </Button>
          <Button onClick={() => console.log('not implemented yet')} disabled={selectedOCREmployees.length === 0}>
            <FormattedMessage id="events.download" />
          </Button>
          <Button onClick={onClose} color="secondary">
            <IntlMessage value={'close'} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
