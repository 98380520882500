import { Buffer } from 'buffer';

type TriggerAWSFileDownloadArgs = {
  fileUrl: string;
  fileName: string;
  isEncodedFilename?: boolean;
};

export const triggerAWSFileDownload = async ({
  fileUrl,
  fileName,
  isEncodedFilename = false,
}: TriggerAWSFileDownloadArgs) => {
  const formattedUrl = formatUrl(fileUrl);
  let url = '';

  try {
    const filename = isEncodedFilename ? getDecodedFilename(fileName) : fileName;

    const response = await fetch(formattedUrl, { mode: 'cors' });
    const blob = await response.blob();
    url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error({
      fileUrl,
      formattedUrl,
      'blob url': url,
      'error message': error.message,
      error,
    });
  }
};

function formatUrl(url: string): string {
  const decodedUrl = decodeURIComponent(url);
  return encodeURI(decodedUrl);
}

function getDecodedFilename(encodedFilename: string): string {
  return Buffer.from(encodedFilename, 'base64').toString('utf8');
}
