import { Grid } from '@mui/material';
import type { UseQueryRefetch } from '../../../../types';
import { RegulationsPanel } from '../RegulationsPanel';
import React from 'react';
import { GROUP_KEYS, type RegulationsFormGroup, type RegulationsFromGroupKeys } from '../RegulationsPanelForm';
import type { SummaryOfRegulation } from '../../../../hooks';

const sortByDescription = (a: SummaryOfRegulation, b: SummaryOfRegulation) => {
  if (a.defaultDescription < b.defaultDescription) {
    return -1;
  }
  if (a.defaultDescription > b.defaultDescription) {
    return 1;
  }
  return 0;
};

const dataReducer = (keys: Array<RegulationsFromGroupKeys>, groups: RegulationsFormGroup) => {
  return keys.reduce((data, k) => {
    const sortedRegulations = (groups[k] || []).sort(sortByDescription);

    if (sortedRegulations.length > 0) {
      data[k] = sortedRegulations;
    }
    return data;
  }, {} as RegulationsFormGroup);
};

type PolicyPackageRegulationsProps = {
  loading: boolean;
  ownerId: string;
  employerId?: string;
  product: any;
  editing: boolean;
  isEmployer: boolean;
  refetchRegulationsQuery: UseQueryRefetch;
  withRegulationsEmployerScales?: boolean;
  groups: RegulationsFormGroup;
  header: any;
};

export const PolicyPackageRegulations = ({
  loading,
  editing,
  isEmployer,
  groups,
  header,
}: PolicyPackageRegulationsProps) => {
  const MAIN_BLOCK = React.useMemo(() => {
    return dataReducer(
      [
        GROUP_KEYS.Amplio,
        GROUP_KEYS.LoonsomVerzuimverzekering,
        GROUP_KEYS.WgaERD,
        GROUP_KEYS.WgaGat,
        GROUP_KEYS.WiaVasteAanvulling,
        GROUP_KEYS.WiaExcedent,
        GROUP_KEYS.CollectiveOngevallen,
        GROUP_KEYS.ZwERD,
        GROUP_KEYS.Inkomensaanvulling,
      ],
      groups,
    );
  }, [groups]);

  const DEFAULT_BLOCK = React.useMemo(() => {
    return dataReducer([GROUP_KEYS.Default], groups);
  }, [groups]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RegulationsPanel
          header={header}
          loading={loading}
          regulations={MAIN_BLOCK}
          isEmployer={isEmployer}
          editing={false}
        />
      </Grid>
      <Grid item xs={12}>
        <RegulationsPanel
          header={header}
          loading={loading}
          regulations={DEFAULT_BLOCK}
          isEmployer={isEmployer}
          editing={editing}
        />
      </Grid>
    </Grid>
  );
};
