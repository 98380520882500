import React from 'react';
import Grid from '@mui/material/Grid';
import {
  FilterableTable,
  type FilterableTableColumnType,
  type FilterableTableRowMapperFunc,
  KebabMenu,
  type KebabMenuItem,
  SnackBar,
} from '../../../../../components';
import { FormattedMessage } from 'react-intl';
import { TranslationKeys } from '../../../../../translations';
import { useStoreSelector } from '../../../../../store';
import { useConnectionGetOCRFilesDataQuery } from '../../../../../hooks/api/connection/useConnectionGetOCRFilesDataQuery';
import { useParams } from 'react-router-dom';
import { OCRStatus, type OCRFile } from '../../../../../types/Connection/ocr.types';
import { useConnectionUpdateOCRFileStatusMutation } from '../../../../../hooks/api/connection/useConnectionUpdateOCRFileStatusMutation';
import { useDialog, useSnakeBar } from '../../../../../hooks';
import { EditRowsPopup } from './components/EditRowsPopup';
import ActionButton from '../../../../../components/ActionButton';
import { ocrFileService } from 'app/Domain/Connections/Services/Connection/OCR';
import { triggerAWSFileDownload } from 'app/utils';

const TABLE_COLUMNS = {
  TITLE: 'title',
  STATUS: 'status',
  DATE: 'date',
  AVERAGE_ACCURACY: 'averageAccuracy',
  ROW_COUNT: 'rowCount',
  ACTIONS: 'actions',
};

export const OCRFilesTab = () => {
  const { showSnackBar } = useStoreSelector(state => state.AppReducer);
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { connectionId } = useParams();
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [fileId, setFileId] = React.useState<string>('');
  const [fileUuid, setFileUuid] = React.useState<string>('');
  const {
    dialogState: stateEditRowsPopup,
    openDialog: openEditRowsPopup,
    closeDialog: closeEditRowsPopup,
  } = useDialog();

  const { mutate: updateOCRFileStatus } = useConnectionUpdateOCRFileStatusMutation();
  const { data: ocrFiles, refetch: reFetchOCRFiles } = useConnectionGetOCRFilesDataQuery({
    variables: {
      connectionId: connectionId,
      perPage,
      page,
    },
  });

  const pagination = React.useMemo(() => {
    return {
      perPage,
      totalCount: ocrFiles?.totalCount ?? 0,
      page,
    };
  }, [ocrFiles?.totalCount, page, perPage]);

  const paginationChange = React.useCallback(({ rowSize, page }) => {
    if (page) {
      setPage(page);
    }
    if (rowSize) {
      setPerPage(rowSize);
    }
  }, []);

  const setFIleIdAndOpenPopup = React.useCallback(
    (fileId: string, fileUuid: string) => {
      setFileId(fileId);
      setFileUuid(fileUuid);
      openEditRowsPopup();
    },
    [openEditRowsPopup, setFileId],
  );

  const onDownloadOCRFileActionClick = React.useCallback(
    async (id, fileName) => {
      try {
        const response = await ocrFileService.downloadOCRfile({
          connectionId: connectionId,
          id: id,
        });

        await triggerAWSFileDownload({
          fileUrl: response.data,
          fileName,
        });
        showSuccessSnakeBar({ method: 'downloadConnectionOCRFile' });
      } catch (error: any) {
        showErrorSnakeBar({ method: 'downloadConnectionOCRFile', message: error });
      }
    },
    [showErrorSnakeBar, showSuccessSnakeBar, connectionId],
  );

  const buildRowActions = React.useCallback(
    (data: OCRFile) => {
      const disabled = data.status !== OCRStatus.WAITING_ON_APPROVAL;
      const actions: Array<KebabMenuItem> = [
        {
          element: <FormattedMessage id={TranslationKeys.connection_processes_button_approve} />,
          disabled: disabled,
          onClick: () => {
            updateOCRFileStatus(
              {
                connectionId,
                id: data.id,
                status: OCRStatus.APPROVED,
              },
              {
                onSuccess: () => {
                  reFetchOCRFiles();
                },
              },
            );
          },
        },
        {
          element: <FormattedMessage id={TranslationKeys.connection_processes_button_decline} />,
          disabled: disabled,
          onClick: () => {
            updateOCRFileStatus(
              {
                connectionId,
                id: data.id,
                status: OCRStatus.FAILED,
              },
              {
                onSuccess: () => {
                  reFetchOCRFiles();
                },
              },
            );
          },
        },
        {
          element: <FormattedMessage id={TranslationKeys.global_download} />,
          onClick: () => onDownloadOCRFileActionClick(data.id, data.fileName),
        },
      ];

      return <KebabMenu items={actions} />;
    },
    [connectionId, updateOCRFileStatus, reFetchOCRFiles, onDownloadOCRFileActionClick],
  );

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<OCRFile>>(
    data => {
      return {
        data: {
          [TABLE_COLUMNS.TITLE]: 'Verzamelloonstaten ' + (data.year ?? ''),
          [TABLE_COLUMNS.STATUS]: <FormattedMessage id={TranslationKeys[`ocr_status_${data.status}`]} />,
          [TABLE_COLUMNS.DATE]: data.uploadDate,
          [TABLE_COLUMNS.AVERAGE_ACCURACY]: data.averageAccuracy ?? '-',
          [TABLE_COLUMNS.ROW_COUNT]: data.rowCount ? (
            <ActionButton
              messageId={String(data.rowCount)}
              onClick={() => setFIleIdAndOpenPopup(data?.id ?? '', data?.fileId)}
              variant={'text'}
            />
          ) : (
            '-'
          ),
          [TABLE_COLUMNS.ACTIONS]: buildRowActions(data),
        },
      };
    },
    [buildRowActions, setFIleIdAndOpenPopup],
  );

  const tableHeaders = React.useMemo<Array<FilterableTableColumnType>>(() => {
    return [
      {
        name: TABLE_COLUMNS.TITLE,
        title: <FormattedMessage id={TranslationKeys.global_title} />,
      },
      {
        name: TABLE_COLUMNS.STATUS,
        title: <FormattedMessage id={TranslationKeys.global_status} />,
      },
      {
        name: TABLE_COLUMNS.DATE,
        title: <FormattedMessage id={TranslationKeys.global_date} />,
      },
      {
        name: TABLE_COLUMNS.AVERAGE_ACCURACY,
        title: <FormattedMessage id={TranslationKeys.ocr_average_accuracy} />,
      },
      {
        name: TABLE_COLUMNS.ROW_COUNT,
        title: <FormattedMessage id={TranslationKeys.ocr_row_count} />,
      },
      {
        name: TABLE_COLUMNS.ACTIONS,
        isActionsColumn: true,
      },
    ];
  }, []);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <FilterableTable
            loading={false}
            columns={tableHeaders}
            rows={ocrFiles?.data ?? []}
            rowMapper={tableRowMapper}
            pagination={pagination}
            onPaginationChange={paginationChange}
          />
        </Grid>
      </Grid>
      <SnackBar message={<FormattedMessage id={TranslationKeys.events_tooManyEvents} />} open={showSnackBar} />
      {stateEditRowsPopup && (
        <EditRowsPopup
          show={stateEditRowsPopup}
          onClose={closeEditRowsPopup}
          onApprove={updateOCRFileStatus}
          fileId={fileId}
          fileUuid={fileUuid}
        />
      )}
    </>
  );
};
